import { MutableRefObject } from 'react';

export const scrollToRef = (ref: MutableRefObject<any>) => {
  if (ref.current && !!window) {
    const offset = -60; // Adjust the scroll by -60px
    const elementPosition = ref.current.getBoundingClientRect().top;
    const offsetPosition = window.scrollY + elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

import clsx from 'clsx';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image from './Image';
import { IImage } from '../types/types';

function CarouselSimple({
  className,
  items,
  height,
  width,
  showArrows = true,
  showDots = false,
}: {
  className?: string;
  items: IImage[];
  height?: string;
  width?: string;
  showArrows?: boolean;
  showDots?: boolean;
}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1280 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1280, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className={clsx(
        'carousel-wrapper relative overflow-hidden pb-16',
        className
      )}
    >
      <Carousel
        additionalTransfrom={0}
        arrows={showArrows}
        showDots={showDots}
        autoPlay
        autoPlaySpeed={3000}
        className="carousel"
        dotListClass="carousel-indicators"
        focusOnSelect={false}
        keyBoardControl
        minimumTouchDrag={80}
        infinite
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        shouldResetAutoplay
        ssr
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        beforeChange={() => {
          document
            .querySelector('.carousel-wrapper')
            ?.classList.add('transitioning');
        }}
        afterChange={() => {
          document
            .querySelector('.carousel-wrapper')
            ?.classList.remove('transitioning');
        }}
      >
        {items.map((item) => (
          <div className="position-relative rounded-3" key={item.src}>
            <a href={item.src} target="_blank" rel="noopener noreferrer">
              <Image
                className="object-fit-cover rounded-3"
                src={item.src}
                alt={item.alt}
                height={height}
                width={width}
              />
            </a>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselSimple;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Layout from './pages/Layout';
import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Studio from './pages/Studio';

import './App.css';
import Lessons from './pages/Lessons';
import Shop from './pages/Shop';
import SectionPortfolio from './components/Portfolio';

export const email = 'diogohleite@me.com';

i18n.use(initReactI18next).init({
  debug: false,
  resources: {
    en: {
      translation: {
        order: 'Order here',
        buy: 'Buy',
        menuCredits: 'Credits',
        menuLessons: 'Lessons',
        menuStudio: 'Studio',
        menuShop: 'Shop',
        menuContact: 'Contact',
        aboutTitle: 'About',
        about1: `Portuguese drummer. He has worked with names such as Prana, Avesso, Fingertips, José Cid, Go’el, Nts, Jonny Abbey, Two Mirrors, Ludgero Rosas, Filipa Azevedo, Swinging Rabbits, Mister Pollen, João Paulo Rodrigues, Pedro Neves, among others...`,
        about2: `When not on the road, he teaches drum lessons at The House of Drummers, and also performs remote recordings in his own studio – The House 5tudi0.`,
        about3: `Diogo Leite is currently endorsed by Sonor, Sabian, and Rohema.`,
        creditsTitle: 'Credits',
        otherExperiencesTitle: 'Other Experiences',
        contactTitle: 'Contact',
        contactDescription:
          'Contacts for concerts, recordings, lessons or workshops:',
        studioTitle: 'Studio',
        studio1: `The House 5tudi0 is a drum tracking studio built in response to changes within the music industry. Due to budget and time restrictions it’s not always possible to record drums the way you’d like, here I’m able to offer flexibility to producers/artists and the opportunity to have live drums on tracks.`,
        // Lessons
        lessonsTitle: 'Lessons',
        lessons1: `The House of Drummers`,
        lessons2: `A space dedicated to drums - since 2008`,
        lessons3: `Drum lessons / Workshops / GuitarDrum Festival`,
        // Shop
        shopTitle: 'Shop',
        shop1: 'Products',
        products: 'Products',
        product1: 'Introdução a Bateria - O Manual I',
        product1Description1:
          'From the rubble of an old family building and the need for a space to practice, Diogo Leite unconsciously sowed the seeds of The House of Drummers. Using the only room, which time had not yet overthrown by the force of the past, he practiced daily, using the place as a rehearsal room for musical projects he was developing.',
        product1Description2:
          'There, the ghosts of Buddy Rich, Mitch Mitchell, Billy Cobham and Dave Grohl converged with the force of the dream, commanding life. ',
        product1Description3:
          'First there were invitations from friends to teach drums and gradually the invitations no longer came from friends but from unknown people prepared to challenge those foundations.',
        product1Description4:
          'As time went by, the life of the building announced its end and those walls could no longer withstand a longitudinal wave coming from a single cymbal or bass drum. In mid-2007, the building was closed and renovated. A year later, a new era was built on the same foundations: The House of Drummers, a space dedicated to the study and all things drums.',
        product1Description5: `While the Earth was circling around the sun 7 times, Diogo Leite's dedication, hard work, commitment and art resulted in countless students, activities and shows. What was missing after building a new modernity? Only his own 'Manual 1'.`,
        // Meta tags translations
        metaHomeTitle: 'Diogo Leite - Drummer',
        metaHomeDescription:
          'Portuguese drummer. He has worked with names such as Prana, Avesso, Fingertips, José Cid, Go’el, Nts, Jonny Abbey, Two Mirrors, Ludgero Rosas, Filipa Azevedo, Swinging Rabbits, Mister Pollen, João Paulo Rodrigues, Pedro Neves, among others...',
        metaCreditsTitle: 'Diogo Leite - Discography and Credits',
        metaCreditsDescription:
          "Explore Diogo Leite's discography and other musical experiences throughout his career as a professional drummer.",
        metaLessonsTitle: 'Diogo Leite - The House of Drummers',
        metaLessonsDescription:
          'Learn drums with Diogo Leite at The House of Drummers. Offering drum lessons, workshops, and the GuitarDrum Festival.',
        metaStudioTitle: 'Diogo Leite - The House 5tudi0',
        metaStudioDescription:
          'Professional drum tracking studio offering flexibility to producers and artists. Get live drums on your tracks with Diogo Leite.',
        metaShopTitle: 'Diogo Leite - Shop',
        metaShopDescription:
          'Shop for drum accessories, merchandise, and educational materials from professional drummer Diogo Leite.',
        metaContactTitle: 'Diogo Leite - Contact',
        metaContactDescription:
          'Contacts for concerts, recordings, lessons or workshops:',
      },
    },
    pt: {
      translation: {
        order: 'Encomenda aqui',
        buy: 'Comprar',
        menuCredits: 'Créditos',
        menuLessons: 'Aulas',
        menuStudio: 'Estúdio',
        menuShop: 'Loja',
        menuContact: 'Contacto',
        aboutTitle: 'Sobre',
        // About
        about1: `Baterista Português. Já trabalhou com nomes como Prana, Avesso, Fingertips, José Cid, Go‘el, Nts, Jonny Abbey, Two mirrors, Ludgero Rosas, Filipa Azevedo, Swinging Rabbits, Mister Pollen, João Paulo Rodrigues, Pedro Neves, entre outros...`,
        about2: `Quando não se encontra na estrada, leciona aulas de bateria na The House of Drummers, além de realizar gravações remotas no seu próprio estúdio - The House 5tudi0.`,
        about3: `Diogo Leite actualmente é patrocinado pela Sonor, Sabian e Rohema.`,
        creditsTitle: 'Créditos',
        otherExperiencesTitle: 'Outras Experiências',
        contactTitle: 'Contacto',
        contactDescription:
          'Contacto para concertos, gravações, aulas ou workshops',
        studioTitle: 'Estúdio',
        studio1: `The House 5tudi0 é um estúdio de gravação de bateria, construído em resposta às mudanças na indústria da música. Devido às restrições de orçamento e tempo, nem sempre é acessível gravar bateria da forma que se pretende, aqui é possível flexibilidade aos produtores/artistas e a oportunidade de terem bateria real nos temas.`,
        lessonsTitle: 'Aulas',
        lessons1: `The House of Drummers`,
        lessons2: `Um espaço dedicado à bateria - desde 2008`,
        lessons3: `Aulas de bateria / Workshops / GuitarDrum Festival`,
        // Shop
        shopTitle: 'Loja',
        shop1: 'Productos',
        products: 'Produtos',
        product1: 'Introdução a Bateria - O Manual I',
        product1Description1:
          'Sobre os escombros de um edifício antigo de gerações familiares e da necessidade de um espaço para praticar Diogo Leite semeava a The House of Drummers inconscientemente. Servindo-se da única sala que o tempo ainda não tinha derrubado pela força do passado foi praticando diariamente aproveitando o local como sala de ensaio para projetos musicais que ia desenvolvendo.',
        product1Description2:
          'Ali convergiam os fantasmas de Buddy Rich, Mitch Mitchell, Billy Cobham, Dave Grohl com a força do sonho comandando a vida.',
        product1Description3:
          'Primeiramente surgem os convites de amigos para ensino de bateria e de uma forma gradual os convites já não provinham de amigos mas de pessoas desconhecidas preparadas para desafiar aqueles alicerces.',
        product1Description4:
          'Com o passar do tempo a vida do edifício anunciava o seu fim e aquelas paredes não conseguiam suportar mais uma onda longitudinal proveniente de um único prato ou bombo. Em meados de 2007 o edifício é encerrado e sofre remodelações. Um ano depois uma nova era se edificava sobre os mesmos alicerces: a ‘The House of Drummers’, um espaço dedicado ao estudo e a toda a circunstância da bateria.',
        product1Description5: `Enquanto a Terra dava 7 voltas em torno do Sol, a dedicação, trabalho, empenho e arte de Diogo Leite traduziu-se em inúmeros alunos, atividades e espetáculos. O que falta ou faltava depois de edificar uma nova modernidade? Apenas o seu próprio ‘Manual 1’.`,
        // Meta tags translations
        metaHomeTitle: 'Diogo Leite - Baterista',
        metaHomeDescription:
          'Baterista Português. Já trabalhou com nomes como Prana, Avesso, Fingertips, José Cid, Go‘el, Nts, Jonny Abbey, Two mirrors, Ludgero Rosas, Filipa Azevedo, Swinging Rabbits, Mister Pollen, João Paulo Rodrigues, Pedro Neves, entre outros...',
        metaCreditsTitle: 'Diogo Leite - Discografia e Créditos',
        metaCreditsDescription:
          'Explore a discografia de Diogo Leite e outras experiências musicais ao longo de sua carreira como baterista profissional.',
        metaLessonsTitle:
          'Aulas de Bateria com Diogo Leite - The House of Drummers',
        metaLessonsDescription:
          'Aprenda bateria com Diogo Leite na The House of Drummers. Oferecendo aulas de bateria, workshops e o GuitarDrum Festival.',
        metaStudioTitle:
          'The House 5tudi0 - Estúdio de Gravação de Bateria por Diogo Leite',
        metaStudioDescription:
          'Estúdio profissional de gravação de bateria oferecendo flexibilidade a produtores e artistas. Obtenha bateria ao vivo nas suas faixas com Diogo Leite.',
        metaShopTitle:
          'Loja Diogo Leite - Acessórios de Bateria e Merchandising',
        metaShopDescription:
          'Compre acessórios de bateria, merchandising e materiais educativos do baterista profissional Diogo Leite.',
        metaContactTitle: 'Diogo Leite - Contacto',
        metaContactDescription:
          'Contacto para concertos, gravações, aulas ou workshops',
      },
    },
  },
  lng: 'pt',
  fallbackLng: 'pt',
  supportedLngs: ['en', 'pt'],
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="credits"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <SectionPortfolio />
              </Suspense>
            }
          />
          <Route
            path="lessons"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Lessons />
              </Suspense>
            }
          />
          <Route
            path="studio"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Studio />
              </Suspense>
            }
          />
          <Route
            path="shop"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Shop />
              </Suspense>
            }
          />
          <Route
            path="contact"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <NotFound />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

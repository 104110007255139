import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import * as changeCase from 'change-case';

import { IPortfolio } from '../types/types';
import { contentPortfolioTemplate } from '../content/portfolio';

export interface IPortfolioContext {
  portfolio: IPortfolio | undefined;
  updatePortfolio: (portfolio: IPortfolio) => void;
  currentCategory: string;
  setCurrentCategory: (category: string) => void;
  currentProject: string;
  setCurrentProject: (project: string) => void;
}

const PortfolioContext = createContext<IPortfolioContext>({
  portfolio: undefined,
  updatePortfolio(): void {
    throw new Error('Function not implemented');
  },
  currentCategory: 'All',
  setCurrentCategory(): void {
    throw new Error('Function not implemented');
  },
  currentProject: 'All',
  setCurrentProject(): void {
    throw new Error('Function not implemented');
  },
});

export const PortfolioProvider = ({ children }: { children: ReactNode }) => {
  const [portfolio, setPortfolio] = useState<IPortfolio>(
    contentPortfolioTemplate
  );
  const [currentCategory, setCurrentCategory] = useState<string>('All');
  const [currentProject, setCurrentProject] = useState<string>('All');

  const updatePortfolio = (portfolio: IPortfolio) => setPortfolio(portfolio);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('category', changeCase.kebabCase(currentCategory));
    url.searchParams.set('project', changeCase.kebabCase(currentProject));
    window.history.replaceState({}, '', url.toString());
  }, [currentCategory, currentProject]);

  return (
    <PortfolioContext.Provider
      value={{
        portfolio,
        updatePortfolio,
        currentCategory,
        setCurrentCategory,
        currentProject,
        setCurrentProject,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolioContext = () => {
  const context = useContext(PortfolioContext);
  if (!context) {
    throw new Error(
      'usePortfolioContext must be used within a PortfolioProvider'
    );
  }
  return context;
};

import { Suspense, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as changeCase from 'change-case';

import GalleryPortfolio from './GalleryPortfolio';
import { IPortfolio, IPortfolioCategory } from '../types/types';
import { usePortfolioContext } from '../context/PortfolioContext';
import { scrollToRef } from '../utils/scrollToRef';
import { contentPortfolio, portfolioCategories } from '../content/portfolio';
import { useTranslation } from 'react-i18next';

/**
 * SectionPortfolio component for displaying a portfolio of projects.
 *
 * @returns {JSX.Element} The rendered portfolio section component.
 */

function SectionPortfolio() {
  const { t } = useTranslation();
  const sectionRef = useRef<any>(null);
  const [searchParams] = useSearchParams();
  const paramCategory = searchParams.get('category');

  const { currentCategory, setCurrentCategory } = usePortfolioContext();

  const [content, setContent] = useState<IPortfolio[]>([]);
  const [categories, setCategories] =
    useState<Array<IPortfolioCategory | { name: 'All'; enabled: true }>>();

  useEffect(() => {
    if (paramCategory) {
      setCurrentCategory(changeCase.capitalCase(paramCategory));
      scrollToRef(sectionRef);
    } else {
      setCurrentCategory('All');
    }
  }, [paramCategory, setCurrentCategory]);

  useEffect(() => {
    setCategories([
      { name: 'All', enabled: true },
      ...portfolioCategories.filter((c) => c.enabled),
    ]);
  }, []);

  useEffect(() => {
    let newContent = contentPortfolio;

    if (currentCategory !== 'All') {
      newContent = newContent?.filter((item) =>
        item.categories.find((category) => category === currentCategory)
      );
    }

    setContent(newContent);
  }, [currentCategory]);

  return (
    <section className="py-20" id="section-portfolio" ref={sectionRef}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h3>{t('creditsTitle')}</h3>
            <div className="mt-5">
              <iframe
                src="https://open.spotify.com/embed/playlist/1ojWEQaV8jSDHVJz5f3who?utm_source=generator&theme=0"
                width="100%"
                height="420"
                frameBorder="0"
                allowFullScreen={false}
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                title="Playlist"
              ></iframe>
            </div>
          </div>

          <div className="col-12 d-flex align-items-end mt-5">
            <div className="category-filters mb-16 d-flex flex-row gap-3 w-100">
              {categories?.map((category) => (
                <button
                  key={category.name}
                  className={`btn btn-dark px-4 ${
                    category.name === currentCategory ? 'active' : ''
                  }`}
                  onClick={() => {
                    setCurrentCategory(category.name);
                  }}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>

          <div className="col-12 mt-5">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row gap-6">
                <h6 className="font-logo text-uppercase">
                  Results: {content?.length}
                </h6>
              </div>
            </div>

            {content?.length > 0 ? (
              <Suspense fallback={<div>Loading...</div>}>
                <GalleryPortfolio items={content} />
              </Suspense>
            ) : (
              <h6 className="font-logo text-uppercase text-white">
                No Results
              </h6>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionPortfolio;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Plx from 'react-plx';
import Credits from './Credits';
import Studio from './Studio';
import Contact from './Contact';
import Lessons from './Lessons';
import Shop from './Shop';
import CarouselSimple from '../components/CarouselSimple';
import CarouselLarge from '../components/CarouselLarge';
import SectionPortfolio from '../components/Portfolio';

const parallaxData = [
  {
    start: 0,
    end: 860,
    properties: [
      {
        startValue: 0,
        endValue: 350,
        property: 'translateY',
      },
    ],
  },
];

const Home: React.FC = () => {
  const { t } = useTranslation();

  const items = [
    {
      src: '/img/home/01.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/03.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/06.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/07.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/09.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/10.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/11.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/12.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/15.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/16.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/17.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/18.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/19.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/20.webp',
      alt: 'Diogo Leite - Drummer',
    },
  ];

  const carouselLargeItems = [
    {
      src: '/img/Diogo Leite.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/03.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/01.webp',
      alt: 'Diogo Leite - Drummer',
    },
  ];
  return (
    <div className="homepage">
      <Plx className="w-full" parallaxData={parallaxData}>
        <CarouselLarge items={carouselLargeItems} height="100%" width="100%" />
      </Plx>

      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <CarouselSimple items={items} height="100%" width="100%" />
            </div>

            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="bio-title mt-lg-0">
                <h3>Diogo Leite</h3>
              </div>
              <div className="bio-text">
                <p>{t('about1')}</p>
                <p>{t('about2')}</p>
                <p>{t('about3')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SectionPortfolio />
      <Studio />
      <Lessons />
      <Shop />
      <Contact />
    </div>
  );
};

export default Home;

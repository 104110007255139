import { IPortfolio, IPortfolioCategory } from '../types/types';

export const contentPortfolioTemplate: IPortfolio = {
  title: '',
  img: '',
  url: '',
  src: '',
  categories: [],
  description: '',
  projects: [],
  year: '',
};

export const portfolioCategories: IPortfolioCategory[] = [
  { name: 'Livros', enabled: true },
  { name: 'Discografia', enabled: true },
  { name: 'Tour', enabled: true },
  { name: 'Workshops', enabled: true },
  { name: 'Endorsements', enabled: true },
];

export const contentPortfolio: IPortfolio[] = [
  {
    img: `/img/credits/Workshop.webp`,
    title: `Workshop`,
    categories: ['Workshops'],
  },
  {
    img: `/img/credits/Vater.webp`,
    title: `Vater`,
    categories: ['Endorsements'],
  },
  {
    img: `/img/credits/Premier Drum Co.webp`,
    title: `Premier Drum Co`,
    categories: ['Endorsements'],
  },
  {
    img: `/img/credits/Masterclass.webp`,
    title: `Masterclass`,
    categories: ['Workshops'],
  },
  {
    img: `/img/credits/GO’EL 2019.webp`,
    title: `GO’EL 2019`,
    categories: ['Tour'],
  },
  {
    img: '/img/credits/Alesis Tour 2016.webp',
    title: 'Alesis Tour 2016',
    categories: ['Tour'],
  },
  {
    img: '/img/credits/Alesis Tour 2017.webp',
    title: 'Alesis Tour 2017',
    categories: ['Tour'],
  },
  {
    img: '/img/credits/Dia do Ritmo 2018.webp',
    title: 'Dia do Ritmo 2018',
    categories: ['Tour'],
  },
  {
    img: '/img/credits/livro-introducao-bateria-2015.webp',
    title: 'Livro "Introdução à bateria" 2015',
    categories: ['Livros'],
    url: '/shop',
  },
  {
    img: '/img/credits/Musical 2018.webp',
    title: 'Unhas Negras - O Espetáculo',
    categories: ['Discografia'],
  },
  {
    img: '/img/credits/Musical 2022.webp',
    title: 'Musical 2022',
    categories: ['Tour'],
  },
  {
    img: '/img/credits/Porto Drum Show 2019.webp',
    title: 'Porto Drum Show 2019',
    categories: ['Tour'],
  },
  {
    img: '/img/credits/2009 REVISTA BATERIA TOTAL.webp',
    title: 'Revista Bateria Total 2019',
    categories: ['Workshops'],
  },
  {
    img: `/img/credits/Drummers World Record.webp`,
    title: 'Drummers World Record',
    categories: ['Tour'],
  },
  {
    img: `/img/credits/Paiste Article.webp`,
    title: `Paiste Article`,
    categories: ['Endorsements'],
  },
  {
    img: `/img/credits/Paiste.webp`,
    title: `Paiste`,
    categories: ['Endorsements'],
  },
];

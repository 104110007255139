import React from 'react';
import { useTranslation } from 'react-i18next';

import CarouselSimple from '../components/CarouselSimple';

const Studio: React.FC = () => {
  const { t } = useTranslation();

  const items = [
    {
      src: '/img/studio/01.webp',
      alt: 'The House 5tudi0',
    },
    {
      src: '/img/studio/02.webp',
      alt: 'The House 5tudi0',
    },
    {
      src: '/img/studio/03.webp',
      alt: 'The House 5tudi0',
    },
    {
      src: '/img/studio/04.webp',
      alt: 'The House 5tudi0',
    },
    {
      src: '/img/studio/07.webp',
      alt: 'The House 5tudi0',
    },
    {
      src: '/img/studio/08.webp',
      alt: 'The House 5tudi0',
    },
    {
      src: '/img/studio/10.webp',
      alt: 'The House 5tudi0',
    },
  ];
  return (
    <section id="studio">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="bio-title mt-5 mt-lg-0">
              <h3>{t('studioTitle')}</h3>
            </div>
            <div className="bio-text">
              <p className="mb-5">{t('studio1')}</p>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-5">
            <CarouselSimple items={items} height="100%" width="100%" />
          </div>

          <div className="col-12 col-lg-6 mb-5">
            <div className="bio-text">
              <p>
                <iframe
                  src="https://open.spotify.com/embed/playlist/0Xely3SqADQwH8lMOLJsb6?utm_source=generator&theme=0"
                  width="100%"
                  height="420"
                  allowFullScreen={false}
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                  title="Playlist"
                ></iframe>
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 col-lg-6">
            <h5 className="mb-4">Drums kit</h5>

            <h6 className="mb-2">
              01 - PREMIER SPITFIRE (BIRCH / OAK SET) - 2012
            </h6>
            <p className="mb-4">
              BD 22"x18" | RackTom 12"x07" | FloorTom 14"x14" | FloorTom 16"x14"
            </p>

            <h6 className="mb-2">02 - SONOR SQ1 (BIRCH SET) - 2017</h6>
            <p className="mb-4">
              BD 20"x16" | RackTom 10"x07" | RackTom 12"x08" | FloorTom 14"x13"
              | FloorTom 16"x15"
            </p>

            <h6 className="mb-2">03 - SONOR VINTAGE (MAPLE) 2024</h6>
            <p className="mb-4">
              BD 24"x14" | Rack Tom 13"x08" | FloorTom 16"x16"
            </p>

            <h6 className="mb-2">04 - SONOR AQ2 (Maple) 2019</h6>
            <p className="mb-4">BD 16"x15" | FloorTom 13"x12"</p>

            <h6 className="mb-2">05 - GRETSCH RENOWN (MAPLE SET) - 2000</h6>
            <p className="mb-4">
              BD 20"x18" | RackTom 10"x8" | RackTom 12"x8" | FloorTom 14"x12"
            </p>

            <h6 className="mb-2">06 - TAMA SWINGSTAR - 1994</h6>
            <p className="mb-4">
              BD 22"x16" | RackTom 13"x11" | FloorTom 16"x16"
            </p>

            <h5 className="mt-5 mb-4">Other Pieces</h5>

            <h6 className="mb-2">TACTON(A) (Maple) - 1950</h6>
            <p className="mb-4">Bass Drum 20"x14"</p>

            <h6 className="mb-2">DEMUSA (MAPLE) - 1970</h6>
            <p className="mb-4">Bass Drum 27"x10"</p>

            <h6 className="mb-2">SONOR VINTAGE (Maple) - 1980</h6>
            <p className="mb-4">RackTom 12"x8"</p>

            <h5 className="mt-5 mb-4">Snares Drums</h5>
            <p>GRETSCH USA Maple: 14"X05"</p>
            <p>GRETSCH Black Hammered: 14"x08"</p>
            <p>SONOR Benny Greb Signature: 13"x5,75"</p>
            <p>TAYE Studio Birch (wood hoop) 14"x07"</p>
            <p>TAMA Lars Ulrich Signature: 14"x6,5"</p>
            <p>PREMIER Modern Classic Maple: 14"x6,5"</p>
            <p>PREMIER Modern Classic Birch: 13"x07"</p>
            <p>TAMA Starclassic: 14"x04"</p>
            <p>TAYE Metalworks Bronze: 14"x6,5"</p>
            <p>SONOR Jungle: 10"x02"</p>
            <p>GRETSCH Free Floating System: 14"X6,5"</p>

            <h5 className="mt-5 mb-4">Drumheads</h5>
            <p>Clear or Coated</p>

            <h5 className="mt-5 mb-4">Percussion</h5>
            <p>Several</p>

            <h5 className="mt-5 mb-4">Hardware</h5>
            <p>Antelope | TLaudio | Motu</p>
            <p>Mac Mini (M1 2020)</p>

            <h5 className="mt-5 mb-4">FX</h5>
            <p>Roland</p>
            <p>T.C. Electronics</p>
            <p>Alesis</p>
          </div>

          <div className="col-12 col-lg-6">
            <h5 className="mb-4">Cymbals</h5>

            <h6 className="mb-3">Hi hat's:</h6>
            <p>15" - Stratus - Sabian</p>
            <p>14" - Artisan Medium - Sabian</p>
            <p>14" - HH Vanguard - Sabian</p>

            <h6 className="mb-3 mt-4">Rides:</h6>
            <p>20" - Artisan Elite - Sabian</p>
            <p>22" - Artisan Light - Sabian</p>
            <p>22" - Artisan medium - Sabian</p>
            <p>22" - HHX Phoenix Big & Ugly - Sabian</p>
            <p>22" - HH Nova Big & Ugly - Sabian</p>
            <p>22" - HH Thin - Sabian</p>

            <h6 className="mb-3 mt-4">Crash's:</h6>
            <p>18" - HHX evolution Dave Weckl - Sabian</p>
            <p>18" - FRX - Sabian</p>
            <p>19" - AAX - Sabian</p>
            <p>20" - AAX - Sabian</p>

            <h6 className="mb-3 mt-4">Others:</h6>
            <p>20" - Zen China HHX - Sabian</p>
            <p>10" - 2002 - Paiste</p>
            <p>10" - HH high Max Stax - Sabian</p>

            <h5 className="mt-5 mb-4">Microphones</h5>
            <p>3x Sennheiser MD421</p>
            <p>1x Sontronics Orpheus</p>
            <p>1x Philips EV 7012 / 500 (built by AKG, identical to D12)</p>
            <p>1x Shure Beta 52A</p>
            <p>1x Studer Revox M3500 (vintage)</p>
            <p>2x Shure Sm57</p>
            <p>1x Shure SM7B</p>
            <p>1x Shure 12A (vintage)</p>
            <p>1x Uher M534 (vintage)</p>
            <p>1x Beyerdynamic M88TG</p>
            <p>1x Audix F6</p>
            <p>2x JZ Microphones Overheads</p>
            <p>2x Rode M5 MP</p>
            <p>1x Rode NT1- A</p>
            <p>1x T-bone SC 400</p>
            <p>1x Solomon SubKick LoFReQ</p>
            <p>1x Telephone (vintage)</p>
            <p>2x RM-5 DIY Ribbon Mic Bumblebee</p>
            <p>1x Trigger Single RT-30H</p>
            <p>1x Trigger Kick RT-30K</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Studio;
